import { HtmlProviderPort } from './htmlProviderPort';
import { AdDetails } from '../../../@types/adCommon';
import { CommonApiImplementation } from '../CommonApiImplementation';
import { createXspClientHtmlProvider } from '../outbound/htmlProvider/xspClientHtmlProvider';
import { createServerHtmlProvider } from '../outbound/htmlProvider/serverHtmlProvider';
import { createOrtbClientHtmlProvider } from '../outbound/htmlProvider/ortbClientHtmlProvider';

export const htmlProvider = (adDetails: AdDetails, sfApi: CommonApiImplementation): HtmlProviderPort => {
    if (adDetails.creative.flow === 'SERVER_SIDE') {
        return createServerHtmlProvider(adDetails.htmlContentEncoded, adDetails.htmlContentEncodedLength);
    } else if (adDetails.creative.flow === 'XSP_CLIENT_SIDE' && adDetails.creative.url) {
        return createXspClientHtmlProvider(sfApi, adDetails.creative.url);
    } else if (adDetails.creative.flow === 'ORTB_CLIENT_SIDE' && adDetails.creative.url) {
        return createOrtbClientHtmlProvider(adDetails.creative.url);
    }

    throw new Error('Wrong creative flow provided');
};
