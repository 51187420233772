import type { Delay } from '../../../@types/adCommon';

/**
 * @deprecated Remove when these types are removed from the config
 */
export const stripRemoveDelayTypes = (delay: Delay) =>
    delay
        ?.replace('criticalFeature', '')
        .replace('windowOnLoad', '')
        .replace('spATFEvent', '')
        .replace('r2OnLoad', '')
        .replace('aboveTheFold', '')
        .replace('af', '');

export const isImmediateOrEmpty = (delayAfter: string) => {
    const cleanedUp = stripRemoveDelayTypes(delayAfter);
    return cleanedUp === '' || cleanedUp === 'immediate';
};
