import { AdResponse, HtmlProviderPort } from '../../domain/htmlProviderPort';
import { HTTP_ERROR, REQUEST_START } from '../../../components/counters/AD_FETCH_COUNTERS';
import { fetchGetAd } from '../../../components/aax/aax';
import * as AD_FETCH_EVENTS from '../../../components/events/AD_FETCH_EVENTS';
import { EMPTY_RESPONSE, ERROR_RETURNED, PUNT_RETURNED } from '../../../components/events/AD_FETCH_EVENTS';
import { CommonApiImplementation } from '../../CommonApiImplementation';

export const createXspClientHtmlProvider = (sfApi: CommonApiImplementation, aaxUrl: string): HtmlProviderPort => {
    return {
        async getAd(): Promise<AdResponse> {
            try {
                sfApi.countMetric(REQUEST_START, 1);
                sfApi.logCsaEvent(AD_FETCH_EVENTS.REQUEST_START);
                const adContent = await fetchGetAd(aaxUrl);
                switch (adContent?.status) {
                    case 'ok': {
                        return Promise.resolve<AdResponse>({
                            creative: tryParseCreativeAsJSON(adContent.creative),
                            creativeTemplateName: adContent.creativeTemplateName,
                            instrumentationPixelUrl: adContent.instrPixelURL,
                            impressionPixelUrl: adContent.impPixelURL,
                        });
                    }
                    case 'punt': {
                        sfApi.countMetric(PUNT_RETURNED, 1);
                        sfApi.logCsaEvent(AD_FETCH_EVENTS.PUNT_RETURNED);
                        break;
                    }
                    case 'error': {
                        sfApi.countMetric(ERROR_RETURNED, 1);
                        sfApi.logCsaEvent(AD_FETCH_EVENTS.ERROR_RETURNED);
                        break;
                    }
                    default: {
                        sfApi.countMetric(EMPTY_RESPONSE, 1);
                        sfApi.logCsaEvent(AD_FETCH_EVENTS.EMPTY_RESPONSE);
                        break;
                    }
                }
            } catch (err) {
                sfApi.countMetric(HTTP_ERROR, 1);
                sfApi.logCsaEvent(AD_FETCH_EVENTS.HTTP_ERROR);
                sfApi.logError("Couldn't fetch Ad From AAX.", err as Error);
            }

            throw new Error('Could not fetch creative html');
        },
    };
};

const tryParseCreativeAsJSON = (responseText: string): string => {
    try {
        return JSON.parse(responseText).creative;
    } catch (err) {
        return responseText;
    }
};
