import type { AdDetails } from '../../@types/adCommon';
import { CommonApiImplementation } from './CommonApiImplementation';
import { ADPT_SF_ENABLE_BLANKADS_DETECTION } from '../components/weblabs';

export const appendBlankAdDetectionScript = (adDetails: AdDetails, sfApi: CommonApiImplementation) => {
    try {
        const blankAdsConfig = adDetails?.blankAdsConfig;
        if (ADPT_SF_ENABLE_BLANKADS_DETECTION().isT1()) {
            const blankAdScript = document.createElement('script');
            blankAdScript.src = blankAdsConfig.blankAdsScriptUrl;
            blankAdScript.defer = true;
            blankAdScript.setAttribute('fetchPriority', 'low');
            (document.head || document.body).appendChild(blankAdScript);
        }
    } catch (ex) {
        sfApi.logError('Error appending blankAdScript', ex as Error);
    }
};
